<template>
  <div>
    <a
      class="has-text-weight-medium has-text-right is-block"
      @click="openModal"
      >{{ $t("af:profile.modal.phone.button") }}</a
    >

    <b-modal v-model="isModalOpen" :can-cancel="false" :width="650">
      <div class="af-modal-card">
        <p class="has-text-weight-bold is-size-4">
          {{ $t("af:profile.modal.phone.title") }}
        </p>

        <p class="mb-6">
          <span v-if="!codeSent">
            {{ $t("af:profile.modal.phone.subtitle") }}
          </span>
          <span v-else>
            {{ $t("af:profile.modal.phone.code_sent.subtitle") }}
          </span>
        </p>

        <span v-if="!codeSent">
          <form @submit.prevent="requestChange" autocomplete="new-password">
            <validation-observer ref="observer">
              <phone-input
                :label="$t('af:profile.modal.phone.input.phone.label')"
                v-model="phoneEdit"
              />

              <BInputWithValidation
                type="password"
                autocomplete="new-password"
                rules="required"
                :label="$t('af:profile.modal.phone.input.password.label')"
                label-position="on-border"
                v-model="password"
              />

              <p class="has-text-weight-bold has-text-centered mt-5">
                <b-button
                  type="is-blue"
                  :value="$t('af:profile.modal.phone.button.save')"
                  tag="input"
                  @click="requestChange"
                ></b-button>
              </p>
            </validation-observer>
          </form>
        </span>

        <span v-else>
          <form @submit.prevent="confirm">
            <b-field
              :label="$t('af:profile.modal.phone.input.two_factor.label')"
              label-position="on-border"
              class="mb-5"
            >
              <b-input v-model="twoFactor.code" type="text"></b-input>
            </b-field>

            <p class="has-text-weight-bold has-text-centered mt-5">
              <b-button
                type="is-blue"
                :value="$t('af:profile.modal.phone.button.confirm')"
                tag="input"
                @click="confirm"
              ></b-button>
            </p>
          </form>
        </span>

        <div class="close-btn cursor-pointer" @click="cancelModal">
          <b-icon icon-pack="fas" icon="times" type="is-grey-icon" />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import { generateNonce } from "../../utils/util";

export default {
  name: "UserEditPhoneModal",
  components: {
    BInputWithValidation: () => import("@/components/BInputWithValidation.vue"),
    PhoneInput: () => import("@/components/PhoneInput.vue"),
  },
  data() {
    return {
      isModalOpen: false,
      phoneEdit: "",
      password: "",
      codeSent: false,
      twoFactor: {
        id: null,
        code: null,
        nonce: null,
      },
    };
  },
  props: {
    phone: {
      type: String,
      required: true,
    },
  },
  methods: {
    openModal() {
      this.phoneEdit = this.phone;
      this.isModalOpen = true;
      this.codeSent = false;
      this.password = null;
      this.twoFactor.code = null;
    },
    cancelModal() {
      this.isModalOpen = false;
    },
    async confirm() {
      try {
        await this.$store.dispatch("profile/confirmPhone", this.twoFactor);
        this.cancelModal();
        this.$emit("save");
      } catch (ex) {
        this.$buefy.toast.open({
          message: i18n.t("af:profile.modal.phone.message.confirmation_error"),
          type: "is-danger",
        });
      }
    },
    async requestChange() {
      const valid = await this.$refs.observer.validate();

      if (!valid) return;

      this.twoFactor.nonce = generateNonce();
      try {
        this.twoFactor.id = await this.$store.dispatch("profile/phoneChange", {
          nonce: this.twoFactor.nonce,
          phone: this.phoneEdit,
          password: this.password,
        });
        this.codeSent = true;
      } catch (ex) {
        if (ex.response.status === 409) {
          this.$buefy.toast.open({
            message: i18n.t("af:profile.modal.phone.message.phone_exist"),
            type: "is-danger",
          });
        }
        if (ex.response.status === 406) {
          this.$buefy.toast.open({
            message: i18n.t("af:profile.modal.phone.message.password_error"),
            type: "is-danger",
          });
        }
      }
    },
  },
};
</script>

<style scoped></style>
